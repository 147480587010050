export const languages: string[] = ['en', 'uk'];

export const positions: Record<string, string> = {
  ST: 'red',
  AL: 'red',
  AM: 'red',
  AR: 'red',
  ML: 'blue',
  MC: 'blue',
  MR: 'blue',
  DM: 'blue',
  DL: 'green',
  DC: 'green',
  DR: 'green',
  GK: 'yellow'
};

export const rows: Record<string, string> = {
  ST: 'firstRow',
  AL: 'secondRowOne',
  AM: 'secondRowTwo',
  AR: 'secondRowThree',
  ML: 'thirdRowOne',
  MC: 'thirdRowTwo',
  MR: 'thirdRowThree',
  DM: 'forthRow',
  DL: 'fifthRowOne',
  DC: 'fifthRowTwo',
  DR: 'fifthRowThree',
  GK: 'sixthRow'
};

export const days: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

export const errorMessages: Record<string, string> = {
  required: $localize`Required`
};

export enum ErrorCode {
  FORBIDDEN_EXCEPTION = 'FORBIDDEN_EXCEPTION',
  UNAUTHORIZED = 'Unauthorized',
  TOKEN_IS_EXPIRED = 'TOKEN_IS_EXPIRED',
  EVENT_IS_PRIVATE = 'EVENT_IS_PRIVATE',
  EVENT_NOT_FOUND = 'EVENT_NOT_FOUND',
  JWT_MUST_BE_PROVIDED = 'jwt must be provided'
}

export enum Hobbies {
  Football = 'football',
  Basketball = 'basketball',
  Other = 'other'
}

export const hobbiesTranslations: Record<any, string> = {
  [Hobbies.Football]: $localize`:@@hobbyFootball:Football`,
  [Hobbies.Basketball]: $localize`:@@hobbyBasketball:Basketball`,
  [Hobbies.Other]: $localize`:@@hobbyOther:Other`
};
