import { Maybe, Message, User } from 'src/app/services/hobbyts.service';
import { IPosition } from '../shared/model/position.interface';
import { positions, rows } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CookieService } from '../services/cookie.service';
import moment from 'moment';
import { StorageService } from '../services/storage.service';

export const generateInitials = (firstName: Maybe<string> | undefined, lastName: Maybe<string> | undefined): string => {
  let first = '';
  if (firstName) {
    first = firstName.charAt(0).toUpperCase();
  }
  let last = '';
  if (lastName) {
    last = lastName.charAt(0).toUpperCase();
  }
  return `${first}${last}`;
};

export const generatePositions = (player: User): IPosition[] => {
  const newPositions: IPosition[] = [];
  if (player.positions) {
    for (const [i, title] of player.positions.entries()) {
      newPositions.push({
        title,
        color: positions[title],
        row: rows[title],
        isFirst: i === 0
      });
    }
  }
  return newPositions;
};

export const generateRow = (title: string, color: string, row: string): IPosition[] => [
  {
    title,
    color,
    added: false,
    row
  }
];

function isDateInThisWeek(date: Date): boolean {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export function calculateTimeOption(startDate: number, endDate?: number): string {
  const currentDay = new Date();
  currentDay.setHours(0, 0, 0, 0);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = endDate ? new Date(endDate).setHours(0, 0, 0, 0) : endDate;
  const currentTime = currentDay.getTime();
  const startDay = new Date(startDate).getDay();

  const difference = new Date(new Date(startDate).getTime() - currentDay.getTime())
  const diffDays = Math.ceil(difference.getTime() / (1000 * 60 * 60 * 24));

  if ((endDate && currentTime > endDate) || (!endDate && currentTime > startDate)) {
    return '';
  } else if ((!endDate && currentTime === startDate) || (endDate && currentTime >= startDate && currentTime <= endDate)) {
    return $localize`today`;
  } else if (startDay - currentDay.getDay() === 1) {
    return $localize`tomorrow`;
  } else if (isDateInThisWeek(new Date(startDate))) {
    return $localize`this week`;
  } else if (diffDays <= 30) {
    return $localize`in ${diffDays} days`;
  } else {
    return $localize`in a future`;
  }
}

export function localizeFreeSpot(playerLimit: number): string {
  const lastDigit = playerLimit % 10;

  if (lastDigit === 1) {
    return $localize`:@@oneFreeSpot:free spot`;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return $localize`:@@twoThreeFourFreeSpots:free spots`;
  } else {
    return $localize`:@@manyFreeSpots:free spots`;
  }
}

export function calculateChatName(message: Message | null): { messageId: string | undefined | null, telegramMessageLink: string, telegramChannelLink: string } {
  const chatName = (message && message.chatId) ? message.chatName || `c/${message.chatId.slice(4)}` : ''
  const messageId = message ? message.id : '';
  const inviteLink = (message && message.inviteLink) ? message.inviteLink : null;
  return {
    messageId,
    telegramMessageLink: `https://t.me/${chatName}/${messageId ?? ''}`,
    telegramChannelLink: `https://t.me/${inviteLink ?? chatName}`
  }
}

export function goBack(location: Location, router: Router, nav_url = "/home"): void {
  if ((location.getState() as any).navigationId > 1) {
    location.back();
  }
  else {
    router.navigate([nav_url]);
  }
}

export function generateEventLink(storageService: StorageService, eventId: string): string {
  return `https://${environment.origin}/event-details/${eventId}?sharedBy=${storageService.getValue('store-userId')}`
}

export function setLangCookies(cookieService: CookieService, country: string, lang: string) {
  cookieService.setCookie('firebase-country-override', country, 30);
  cookieService.setCookie('firebase-language-override', lang, 30);
}

export function getCityName(cityName: string): { formatted_address: string; name: string } {
  const formatted_address = cityName.replace(/^\S+\s/, "").trim();
  return {
    formatted_address,
    name: formatted_address.replace(/^([^,]+).*$/, '$1').trim()
  }
}

export function getCityPostsData(storageService: StorageService): { count: number; lastEditTimestamp: number } {
  const storedDataString = storageService.getValue('store-cityInputData');
  
  if (storedDataString) {
    const storedData = JSON.parse(storedDataString);
    const lastEditTimestamp = moment(storedData.lastEditTimestamp);
    const currentTimestamp = moment();
    
    if (!lastEditTimestamp.isSame(currentTimestamp, 'day')) {
      storedData.count = 0;
    }

    return storedData;
  } else {
    return { count: 0, lastEditTimestamp: 0 };
  }
}

export function canMakeCityPost(postsData: {count: number, lastEditTimestamp: number}): boolean {
  return (moment().diff(moment(postsData.lastEditTimestamp), 'minutes') >= 1 && postsData.count < 5) || postsData.lastEditTimestamp === 0
}